<template>
  <button
    class="font-display inline-flex items-center justify-center whitespace-nowrap rounded-lg font-semibold uppercase transition duration-200 ease-in-out focus:ring-2 focus:ring-offset-white"
    :class="[classes]"
    tabindex="0">
    <slot />
  </button>
</template>

<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
})

const classes = computed(() => {
  return {
    // Sizes
    'h-8 px-2 gap-1.5 text-sm': props.size === 'xs',
    'h-9 px-3 gap-1.5 text-sm': props.size === 'sm',
    'h-10 px-3.5 gap-2 text-base': props.size === 'md',
    'h-12 px-4 gap-1 text-lg': props.size === 'lg',
    'h-16 font-semibold px-6 gap-1 text-xl gap-3': props.size === 'xl',
    // Colors
    'bg-green-500 text-white hover:bg-green-600 hover:shadow-lg': props.color === 'primary',
    'bg-white text-gray-600 shadow-none hover:shadow-lg border': props.color === 'secondary',
    'border-transparent bg-yellow-400 text-gray-800 hover:bg-yellow-500 hover:text-gray-900': props.color === 'alert',
    'border-red-200 border bg-transparent text-red-500 hover:bg-red-600 hover:border-red-600 hover:text-white': props.color === 'danger',
    'border-transparent border bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600': props.color === 'clear',
    'bg-gray-800 text-white hover:bg-gray-900 hover:shadow-lg': props.color === 'dark',
  }
})
</script>

<style scoped>
  button:disabled {
    opacity: 0.5;
    cursor: wait;
  }
</style>
